/* ------------------------ */
/*      CUSTOM PLUGINS      */
/* ------------------------ */

.owl-carousel {
  position: relative;
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
  .owl-stage-outer {
    margin-bottom: 20px;
    .owl-stage {
      .owl-item {
        position: relative; } } }
  .owl-nav {
    position: absolute;
    top: calc(50% - 15px);
    width: 100%;
    .owl-prev,
    .owl-next {
      position: absolute;
      width: 30px;
      font-size: 1.75rem;
      transition: all .3s;
      &:focus {
        outline: none !important; }
      &:hover {
        color: $primary; } }
    .owl-prev {
      left: 0;
      text-align: left; }
    .owl-next {
      text-align: right;
      right: 0; } }

  .owl-dots {
    text-align: center;
    position: absolute;
    width: 100%;
    bottom: 15px;
    line-height: 10px;
    .owl-dot {
      display: inline-block;
      margin: 0 10px;
      span {
        display: block;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: #eee; }
      &:focus {
        outline: none !important; }
      &.active {
        span {
          background-color: $primary; } } } } }


%owl-animate {
  .owl-stage {
    transform: none !important;
    width: 100% !important;
    position: relative;
    height: 0;
    border: none;
    overflow: hidden;
    display: block;
    padding-top: 31.25%; } // 1600x500

  .owl-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    opacity: 0;
    z-index: 10;

    &.active {
        z-index: 20;
        opacity: 1; }
    &.owl-animated-out {
        opacity: 1; }

    &.owl-animated-in {
        opacity: 0; } } }

.fxPushReveal {
  @extend %owl-animate;

  .owl-item.animated {
    opacity: 1;
    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out; } }



.fxPushRevealOutNext {
  animation-name: slideOutBottom; }


.fxPushRevealInNext {
  animation-name: slideInHalfFromTop;

  .fxPushReveal &.owl-item {
    z-index: 5; } }



.fxPushRevealOutPrev {
  animation-name: slideOutHalfTop; }


.fxPushRevealInPrev {
  animation-name: slideInFromBottom; }


@keyframes slideOutBottom {
  from {}
  to {
    transform: translateY(100%); } }



@keyframes slideInHalfFromTop {
  from {
    transform: translateY(-50%); }

  to {
    transform: translateY(0); } }



@keyframes slideOutHalfTop {
  from {}
  to {
    transform: translateY(-50%); } }



@keyframes slideInFromBottom {
  from {
    transform: translateY(100%); }

  to {
    transform: translateY(0); } }


