/* ---------------- */
/*      FOOTER      */
/* ---------------- */

footer {
  background: #dbdce1;
  ul {
    padding-left: 0;
    li {
      list-style: none;
      margin-bottom: 10px;
      i {
        font-size: 20px;
        line-height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 15px;
        min-width: auto; }
      a {
        transition: all .3s !important;
        &:hover {
          color: $primary !important; } } } }
  .foot-top {
    padding-top: 80px; }
  .foot-title,
  h3 {
    font-size: 21px;
    line-height: 30px;
    margin-bottom: 20px;
    color: #222; }
  .foot-logo {
    margin-bottom: 20px; }
  .icon {
    width: 20px;
    height: 20px;
    display: inline-block;
    // background-color: red
    position: relative;
    vertical-align: middle;
    margin-right: 15px;
    background-size: contain;
    filter: grayscale(100%); }
  .foot-social-media {
    &> li {
      &> a {
        font-size: 16px;
        line-height: 24px;
        transition: all .3s;
        &:hover {
          .icon {
            filter: grayscale(0%); } } } } }
  .organised {
    span {
      font-size: 16px;
      line-height: 21px;
      display: block;
      margin-bottom: 10px; }
    h4 {
      font-size: 18px;
      line-height: 24px;
      color: #1d2746;
      margin-bottom: 0; } }
  .newsletter {
    .form-control {
      max-width: 220px;
      display: inline-block;
      border-color: #f8f8f8;
      background: #FFFFFF;
      margin-right: 10px;
      margin-bottom: 15px;
      &:focus {
        border-color: $primary; } }
    .form-control,
    .btn {
      vertical-align: top; }
    .btn {
      background: #1d2746; } }

  .foot-bot {
    text-align: center;
    background: rgba(29, 39, 70, 1);
    padding: 30px 0 30px;
    a {
      display: inline-block;
      margin: 0 15px;
      color: #FFF;
      opacity: 1;
      transition: all .3s;
      &:hover {
        color: #FFF;
        opacity: .5; } } } }

