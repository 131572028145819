@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str}; }

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args; }

@mixin placeholder($color) {
  &::placeholder {
    color: $color; }
  &:-ms-input-placeholder {
    color: $color; }
  &::-ms-input-placeholder {
    color: $color; } }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); }

@mixin transform($translate3d) {
  transform: $translate3d;
  -webkit-transform: $translate3d;
  -moz-transform: $translate3d;
  -o-transform: $translate3d;
  -ms-transform: $translate3d; }

@mixin background($path,$size: auto,$position:0 0,$repeat: no-repeat) {
  background: transparent url(#{$path}) $repeat $position;
  background-size: $size;
  -webkit-background-size: $size;
  -moz-background-size: $size; }
@mixin backgroundSize($val) {
  background-size: $val;
  -webkit-background-size: $val;
  -moz-background-size: $val; }
@mixin filter($value) {
  -webkit-filter : $value;
  filter: $value; }
@mixin center() {
  align-items: center;
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  box-align: center; }

@mixin responsive($max:0,$min: 0) {
  @if $min == 0 {
    @media(max-width: $max) {
      @content; } }
  @else if $max == 0 {
    @media(min-width: $min) {
      @content; } }
  @else {
    @media(max-width: $max) and  (min-width: $min) {
      @content; } } }
@mixin mobile() {
  @include responsive(767px) {
    @content; } }
@mixin maxtablet() {
  @include responsive(991px) {
    @content; } }
@mixin mintablet() {
  @include responsive(0,768px) {
    @content; } }
@mixin minltablet() {
  @include responsive(0,992px) {
    @content; } }
@mixin tablet() {
  @include responsive(992px,768px) {
    @content; } }
@mixin ltablet() {
  @include responsive(1200px,992px) {
    @content; } }
@mixin sdekstop() {
  @include responsive(1200px,1024px) {
    @content; } }
@mixin large() {
  @include responsive(0,1200px) {
    @content; } }

//IE8

@mixin clearfix() {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table; }
  &:after {
    clear: both; } }

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

@mixin imgBg($padding) {
  @include backgroundSize(cover);
  background-position: center;
  background-color: #eee;
  display: block;
  &:before {
    content: "";
    display: block;
    padding: $padding; } }

@mixin absfull() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

@mixin bgcover() {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

@mixin fontGenerator($name,$weight,$family) {
  @for $i from 1 through length($family) {
    $src: #{"../fonts/"}#{nth($family, $i)};
    @font-face {
      font-family : $name;
      src         : local($name);
      src         : url("#{$src}.eot");
      src         : url("#{$src}.svg") format("svg"), url("#{$src}.eot#iefix") format("embedded-opentype"), url("#{$src}.ttf") format("truetype"), url("#{$src}.woff") format("woff");
      font-style  : normal;
      font-weight : nth($weight, $i); } } }
