/* ----------------- */
/*      GENERAL      */
/* ----------------- */

body {
  overflow-x: hidden; }

.container-sm {
  margin-right: auto;
  margin-left: auto;
  max-width: 800px;
  padding-left: 15px;
  padding-right: 15px;
  @include mobile {
    max-width: 100%; } }

h4 {
  font-size: 18px;
  line-height: 26px; }

.btn {
  position: relative;
  font-size: 16px;
  line-height: 40px;
  font-weight: 400;
  padding: 0 15px;
  border: 0;
  border-radius: 3px;
  text-align: center;
  transition: all .3s ease;
  &:focus,
  &:active:focus,
  &.active:focus {
    outline: none !important; }
  &:focus {
    box-shadow: inset 0px 3px 6px 0px rgba(0,0,0,0.25) !important; }
  &.btn-primary {
    background: $primary;
    color: #FFF;
    &:hover,
    &:active,
    &.active {
      background: darken($primary, 10%) !important;
      color: #FFF; } }
  &.btn-secondary {
    background: $secondary;
    color: #FFF;
    &:hover,
    &:active,
    &.active {
      background: darken($secondary, 10%) !important; } }
  &.btn-gray {
    background: #DDD;
    color: #444;
    &:hover,
    &:active,
    &.active {
      background: darken(#DDD, 10%) !important; } }
  &.btn-highlight {
    line-height: 60px;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 30px; }
  &.btn-sm {
    font-size: 14px;
    line-height: 30px; } }



.bg-gray {
  background: #f6f6f6; }

.btn-default {
  background: #b82125;
  color: #FFF;
  font-weight: 500;
  text-transform: uppercase; }


.page-menu {
  background-color: #eeeeee;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    li {
      display: inline-block;
      &> a {
        display: block;
        padding: 10px 10px;
        transition: all .3s;
        border-radius: 3px 3px 0 0; }
      &.current-menu-item {
        a {
          color: $primary;
          font-weight: 600;
          pointer-events: none;
          &:hover {
            color: $text; } } } } } }

.section-title {
  font-size: 26px;
  line-height: 40px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
  .blue {
    color: #0095da; }
  .black {
    color: #222222; } }
.section-subtitle {
  h4 {
    font-size: 18px; } }

.page-menu {
  background-color: #eeeeee;
  position: relative;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      &> a {
        display: block;
        padding: 10px 10px;
        transition: all .3s;
        border-radius: 3px 3px 0 0; }
      &.current-menu-item {
        a {
          color: $primary;
          font-weight: 600;
          pointer-events: none;
          &:hover {
            color: $text; } } }
      &.has-sub {
        position: relative;
        &> a {
          &:after {
            content: "\f0d7";
            font-family: 'Font Awesome 5 Pro';
            display: inline-block;
            margin-left: 8px;
            font-size: 13px; } }
        &:hover {
          &> .sub-menu {
            padding-top: 10px;
            opacity: 1;
            visibility: visible; } } }
      .sub-menu {
        position: absolute;
        top: 100%;
        list-style: none;
        text-align: left;
        padding: 0;
        margin: 0;
        min-width: 200px;
        opacity: 0;
        visibility: hidden;
        transition: all .4s;
        z-index: 20;
        &> li {
          display: block;
          border-radius: 0;
          &> a {
            display: block;
            padding: 8px 15px;
            background: rgba(29, 39, 70, 0.9);
            border-radius: 0;
            color: #FFF;
            transition: all .3s; }
          &:hover > a {
            background: rgba(29, 39, 70, 1); } } } } }

  @include mobile {
    text-align: left;
    ul {
      padding-top: 44px;
      li {
        display: block;
        max-height: 0;
        opacity: 0;
        visibility: hidden;
        transition: all .3s linear;
        &> a {
          text-align: left; }
        &.current-menu-item {
          max-height: 50px;
          opacity: 1;
          visibility: visible;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          pointer-events: none;
          &> a {
            color: $text;
            text-align: left;
            padding-left: 25px;
            &:after {
              content: "\f0d7";
              font-family: 'Font Awesome 5 Pro';
              display: inline-block;
              position: absolute;
              top: 10px;
              right: 15px;
              margin-left: 8px;
              transform: rotate(0);
              font-size: 16px;
              transition: all .3s; } } } } }
    &.menu-open {
      ul {
        li {
          max-height: 50px;
          opacity: 1;
          visibility: visible;
          &.current-menu-item > a:after {
            transform: rotate(180deg); } } } } } }

.modal {
  .modal-content {
    position: relative; }
  .modal-close {
    cursor: pointer;
    color: #FFF;
    font-size: 14px;
    line-height: 20px;
    position: absolute;
    right: -60px;
    top: 25px;
    text-align: center;
    opacity: 1;
    transition: opacity .5s;
    span {
      display: block; }
    &:before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      background-image: url(../images/icon-close.svg);
      margin: 0 auto 10px; }
    &:hover {
      opacity: .5; }
    @include mobile {
      right: 0;
      top: -50px; } }

  .modal-dialog .modal-content {
    box-shadow: 0 20px 60px 0 rgba(0,0,0,0.5);
    border: 0;
    border-radius: 0.125rem;
    background-color: #FFF; }
  .modal-header {
    border: 0;
    padding: 60px 60px 0; }
  .modal-title {
    font-size: 21px;
    line-height: 30px;
    font-weight: 600;
    &:last-child {
      margin-bottom: 0; } }
  .modal-body {
    padding: 50px;
    &.psmall {
      padding: 15px; } }

  &.modal-video {
    .modal-body {
      padding: 10px; }
    .video-embed {
      width: 100%;
      padding-top: 56.2%;
      position: relative;
      iframe {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0; } }
    @include mobile {
      background-color: #000000;
      .modal-dialog {
        margin: 0;
        width: 100%;
        .modal-content {
          .modal-close {
            top: 10px;
            right: 10px;
            z-index: 99; }
          .modal-body {
            padding: 0;
            border-radius: 0;
            background-color: #000000; } } } } } }

.page-numbers {
  width: 100%;
  &> ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      padding: 0 0.5rem;
      margin: 0;
      .page-number {
        display: block;
        color: $text;
        &:hover {
          color: $primary; }
        &.current {
          font-weight: 800; }
        &.disabled {
          display: none; } }
      span.page-number:hover,
      .page-number.current {
        color: $text; } } } }

.modal-banner {
  .modal-body {
    padding: 15px;
    text-align: center; } }

.partners-logo {
  .section-title {
    font-size: 22px;
    line-height: 36px; } }

.mb-20 {
  margin-bottom: 20px; }
.mb-30 {
  margin-bottom: 30px; }
.my-30 {
  margin-top: 30px;
  margin-bottom: 30px; }
.mb-60 {
  margin-bottom: 60px; }
.mb-80 {
  margin-bottom: 80px; }

.py-60 {
  padding-top: 60px;
  padding-bottom: 60px; }
.py-80 {
  padding-top: 80px;
  padding-bottom: 80px; }
.pt-80 {
  padding-top: 80px; }
.pb-80 {
  padding-bottom: 80px; }

@include mobile {
  .mb-20 {
    margin-bottom: 10px; }
  .mb-30 {
    margin-bottom: 20px; }
  .mb-60 {
    margin-bottom: 30px; }
  .py-60 {
    padding-top: 30px;
    padding-bottom: 30px; }
  .py-80 {
    padding-top: 40px;
    padding-bottom: 40px; }
  .pt-80 {
    padding-top: 40px; }
  .pb-80 {
    padding-bottom: 40px; } }
