/* -------------- */
/*      FORM      */
/* -------------- */

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }
input[type=number] {
  -moz-appearance: textfield; }

.form-group label {
  display: block; }

.form-control {
  font-size: 15px;
  border: 1px solid $border;
  border-radius: 2px;
  height: 40px;
  color: $text;
  &:focus {
    box-shadow: none;
    border-color: $border; }
  &::placeholder {
    color: $text-light; } }
.form-control:disabled,
.form-control[readonly] {
  background-color: #F9f9F9; }
.w-60 {
  display: inline-block;
  vertical-align: middle;
  width: 60px;
  margin: 0 5px; }

.custom-control + .custom-control {
  margin-top: 10px; }

.select-control {
  background: #FFFFFF;
  border: 1px solid #E2E4E6;
  border-radius: 2px;
  color: $text;
  font-size: 16px;
  font-weight: normal;
  padding: 0 12px; }
.dropdown {
  .inner {
    &::-webkit-scrollbar {
      width: 5px; }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 10px; }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 149, 218, .5);
      border-radius: 5px; } }
  .dropdown-menu {
    border: 0;
    border-radius: 2px;
    padding: 0;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.25);
    &> li > a,
    &> li > span, {
      display: block;
      padding: 7px 15px;
      color: $text;
      &.dropdown-item.active,
      &.dropdown-item:active,
      &:hover,
      &:focus,
      &:active,
      &:active:focus {
        background-color: #eee;
        color: $text;
        outline: none !important; } } } }


.file-control.file-images {
  text-align: left;
  input[type="file"] {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden; }

  .file-delete {
    display: none;
    margin-left: 15px;
    cursor: pointer; }
  &.has-file {
    .file-delete {
      display: inline-block; } } }


.button-toggle {
  width: 100%;
  input {
    position: absolute;
    z-index: -1;
    opacity: 0; }
  input:checked + .btn {
    background-color: $primary;
    color: #FFF; } }



.form-action {
  text-align: center;
  .btn {
    padding: 0 30px;
    min-width: 120px; }
  .btn + .btn {
    margin-left: 8px; } }

.custom-radio.others {
  .row {
    align-items: center; } }

.form-login {
  p {
    margin: 0 0 20px; }
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
  textarea {
    font-size: 15px;
    border: 1px solid #E2E2E2;
    border-radius: 2px;
    height: 40px;
    color: #444444;
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
  textarea {
    height: auto; }
  input[type=submit] {
    margin: 0 !important;
    position: relative;
    font-size: 16px;
    line-height: 40px !important;
    font-weight: 400;
    padding: 0 15px;
    border: 0;
    border-radius: 3px;
    text-align: center;
    transition: all .3s ease;
    background-color: $primary;
    color: #FFF;
    min-width: 120px;
    width: 100%;
    cursor: pointer;
    &:focus {
      box-shadow: inset 0px 3px 6px 0px rgba(0,0,0,0.25) !important; }
    &:hover,
    &:active,
    &.active {
      background: darken($primary, 10%) !important; } } }

.wppb-error {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
  border-radius: 3px;
  position: relative;
  padding: .75rem 1.25rem !important;
  margin-bottom: 1rem;
  text-align: center; }

#wppb-login-wrap .login-username label,
#wppb-login-wrap .login-username input,
#wppb-login-wrap .login-password label,
#wppb-login-wrap .login-password input {
  width: 100% !important;
  display: block !important;
  text-align: left !important; }

#wppb-login-wrap .login-remember {
  display: none !important; }




// GFORM
body {
  .gform_wrapper {
    ul {
      li.gfield {
        margin: 0 0 20px !important;
        padding: 0 !important;

        .gfield_label {
          display: block;
          margin: 0 0 .5rem;
          font-size: 16px;
          line-height: 24px;
          font-weight: normal; }

        .ginput_container {
          margin: 0 !important;
          padding: 0 !important;
          input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
          textarea {
            font-size: 15px;
            border: 1px solid #E2E2E2;
            border-radius: 2px;
            height: 40px;
            color: #444444;
            display: block;
            width: 100%;
            padding: .375rem .75rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; }
          textarea {
            height: auto; } } }
      li.gsection {
        border: 0;
        margin-bottom: .5rem !important; }
      li.radio-inline {
        ul.gfield_radio li {
          display: inline-block;
          margin: 0 1.5rem 0 0;
          label {
            white-space: nowrap; } } }
      li.radio-btn {
        ul.gfield_radio {
          width: auto !important;
          margin: 0 -15px !important;
          li {
            display: inline-block;
            margin: 0;
            padding: 0 15px !important;
            width: 50%;
            position: relative;
            label {
              width: 100%;
              max-width: 100%;
              background-color: #f8f9fa;
              color: #212529;
              line-height: 40px;
              border-radius: 3px;
              padding: 0 15px;
              text-align: center;
              &:hover {
                background-color: #e2e6ea; }
              &:before,
              &:after {
                display: none; } }
            input[type=radio]:checked + label {
              background: $primary;
              color: #FFF; } } } } }

    .gfield_required {
      display: none !important; }
    .gsection .gfield_label,
    h2.gsection_title,
    h3.gform_title {
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      margin: 0 0 20px;
      color: $text;
      border: 0; }

    ul.gfield_checkbox,
    ul.gfield_radio {
      margin: 0 0 20px !important;
      li {
        input[type=radio],
        input[type=checkbox] {
          position: absolute;
          opacity: 0;
          visibility: hidden; }
        label {
          margin: 0;
          font-size: 16px;
          line-height: 24px;
          color: $text;
          position: relative;
          padding-left: 1.5rem;
          &:before,
          &:after {
            position: absolute;
            top: .25rem;
            left: 0;
            display: block;
            width: 1rem;
            height: 1rem;
            border-radius: 50%;
            pointer-events: none;
            content: "";
            background-color: #dee2e6;
            transition: all .3s; }
          &:after {
            width: .4rem;
            height: .4rem;
            left: .3rem;
            top: .55rem;
            background-color: #FFF;
            opacity: 0; } }
        input[type=radio]:checked + label {
          font-weight: normal;
          &:before {
            background-color: $primary; }
          &:after {
            opacity: 1; } } } }
    ul.gfield_checkbox {
      li {
        label {
          &:before {
            border-radius: 3px; }
          &:after {
            content: "\f00c";
            font-family: 'Font Awesome 5 Pro';
            width: 1rem;
            height: 1rem;
            left: 0;
            top: .25rem;
            color: #FFFFFF;
            background-color: transparent;
            line-height: 1em;
            text-align: center;
            font-weight: 0.98rem; } }
        input[type=checkbox]:checked + label {
          font-weight: normal;
          &:before {
            background-color: $primary; }
          &:after {
            opacity: 1; } } } }



    .gform_footer {
      margin: 0 !important;
      padding: 0 !important;
      text-align: center;
      .gform_button {
        margin: 0 !important;
        position: relative;
        font-size: 16px;
        line-height: 40px !important;
        font-weight: 400;
        padding: 0 15px;
        border: 0;
        border-radius: 3px;
        text-align: center;
        transition: all .3s ease;
        background-color: $secondary;
        color: #FFF;
        min-width: 120px;
        cursor: pointer;
        &:focus {
          box-shadow: inset 0px 3px 6px 0px rgba(0,0,0,0.25) !important; }
        &:hover,
        &:active,
        &.active {
          background: darken($secondary, 10%) !important; } } } }

  .gform_ajax_spinner {
    width: 30px;
    height: auto; }
  //   display: none !important
  //ERROR
  .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
    width: 100% !important;
    max-width: 100% !important; }
  .validation_error {
    display: none !important; }
  .gform_wrapper li.gfield.gfield_error, .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
    border: 0;
    background-color: transparent; }
  .gform_wrapper .description, .gform_wrapper .gfield_description, .gform_wrapper .gsection_description {
    padding-top: 0 !important;
    font-weight: normal; }

  .gform_wrapper .gfield_error .gfield_label {
    color: $text; }
  .gform_wrapper .validation_message {
    color: #d63031;
    font-weight: normal; }

  // SUCCESS
  .gform_confirmation_message {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid #c3e6cb;
    border-radius: 3px;
    color: #155724;
    background-color: #d4edda;
    text-align: center; } }



body .newsletter {
  .gform_body {
    display: inline-block !important;
    vertical-align: top !important;
    width: calc(100% - 130px) !important;
    padding-right: 10px !important;
    ul li.gfield .gfield_label {
      display: none !important; } }
  .gform_footer {
    display: inline-block !important;
    vertical-align: top !important;
    width: 100px !important;
    .gform_button {
      min-width: 100px !important;
      background-color: #003b60 !important; } }

  @include maxtablet {
    .gform_body,
    .gform_footer {
      width: 100% !important;
      padding-right: 0 !important;
      .gform_button {
        width: 100% !important; } } } }

