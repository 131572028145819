/* main css v1.5.1 */


@import 'cores/mixin';

// Color
$primary: #005A93;
$primary-dark: #005599;
$secondary: #b82125;
$secondary-dark: darken($secondary, 5%);
$text: #222;
// $text: #1d2746
$heading: #b82125;
$text-light: #666666;
$border: #E2E2E2;


/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/fonts';
@import 'cores/base';


/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/animation';
@import 'components/custom-plugins';
@import 'components/general';
@import 'components/form';
@import 'components/header';
@import 'components/footer';
@import 'components/content';
