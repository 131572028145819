/* ---------------- */
/*      HEADER      */
/* ---------------- */

header {
  padding: 10px 0;
  border-bottom: 1px solid $border;
  position: relative;
  z-index: 100;
  background: #FFF;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  transition: all .5s;
  &.fixed {
    top: -100px; }
  &.sticky {
    top: 0; }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #1d2746;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease; }
  .container {
    display: flex; }
  .logo {
    a {
      display: block;
      max-height: 80px;
      position: relative;
      margin: 0;
      padding: 0;
      img {
        position: relative;
        max-height: 80px;
        width: auto; } } }

  .main-menu {
    margin-left: auto;
    text-align: right;
    .date {
      display: block;
      text-align: right;
      margin: 10px 0 12px;
      min-height: 24px;
      color: $primary-dark;
      font-size: 18px;
      line-height: 24px;
      position: relative;
      font-weight: 600;
      margin-right: 20px;
      &:last-child {
        margin-right: 0; }
      .separator {
        display: inline-block;
        margin: 0 10px;
        min-height: 1em;
        &:before {
          content: "";
          display: block;
          width: 6px;
          height: 6px;
          border-radius: 3px;
          position: relative;
          background: $primary-dark;
          margin: 9px 0 0; } } }
    .main-menu-wrap,
    .top-menu-wrap {
      display: block;
      padding: 0;
      margin: 0;
      text-align: right;
      list-style: none;
      &> li {
        display: inline-block;
        padding: 0 12px;
        position: relative;
        &.current-menu-item a {
          color: $primary; }
        &:last-child {
          padding-right: 0; }
        &.highlight > a {
          font-weight: 600;
          text-transform: uppercase;
          color: lighten(#1d2746, 20%);
          &:hover {
            color: $primary-dark; } }
        &.hidden-lg {
          display: none;
          @include responsive(1100px, 0) {
            display: inline-block; } }
        &.hidden-md {
          display: inline-block;
          @include responsive(1100px, 0) {
            display: none; } }
        &.button > a {
          background-color: #b82125;
          color: #FFF;
          display: block;
          padding: 3px 12px;
          border-radius: 3px;
          transition: all .3s;
          font-weight: normal;
          text-transform: capitalize;
          &:hover {
            background-color: darken(#b82125, 5%);
            color: #ffffff; } }
        &.highlight {
          &.current-menu-item > a {
            text-decoration: underline;
            color: $primary; }
          &.button.current-menu-item  > a {
            background-color: darken(#b82125, 11%);
            color: #ffffff !important;
            text-decoration: none; } }
        &.has-sub {
          &> a {
            &:after {
              content: "\f0d7";
              font-family: 'Font Awesome 5 Pro';
              font-weight: bold;
              display: inline-block;
              margin-left: 8px;
              font-size: 13px; } }
          &:hover {
            &> .sub-menu {
              padding-top: 10px;
              opacity: 1;
              visibility: visible; } } }
        .sub-menu {
          position: absolute;
          top: 100%;
          list-style: none;
          text-align: left;
          padding: 0;
          margin: 0;
          min-width: 200px;
          opacity: 0;
          visibility: hidden;
          transition: all .4s;
          &> li {
            &> a {
              display: block;
              padding: 8px 15px;
              background: rgba(29, 39, 70, 0.9);
              color: #FFF;
              transition: all .3s; }
            &:hover > a {
              background: rgba(29, 39, 70, 1); }
            &.has-sub-2 {
              position: relative;
              &> a {
                &:after {
                  content: "\f0da";
                  font-family: 'Font Awesome 5 Pro';
                  font-weight: bold;
                  display: inline-block;
                  margin-left: 8px;
                  font-size: 13px;
                  float: right; } }
              .sub-menu-2 {
                position: absolute;
                left: calc(100% - 30px);
                top: 0;
                list-style: none;
                padding: 0;
                margin: 0;
                min-width: 200px;
                opacity: 0;
                visibility: hidden;
                transition: all .3s ease;
                &> li > a {
                  display: block;
                  padding: 8px 15px;
                  background: rgba(29, 39, 70, 0.9);
                  color: #FFF;
                  transition: all .3s;
                  &:hover {
                    background: rgba(29, 39, 70, 1); } } }
              &:hover {
                .sub-menu-2 {
                  opacity: 1;
                  visibility: visible;
                  left: 100%; } } } } } } }

    .top-menu-wrap {
      &> li {
        padding: 0 5px; }
      &> li > a {
        background-color: $primary;
        color: #FFF;
        display: block;
        padding: 3px 12px;
        border-radius: 3px;
        transition: all .3s;
        &:hover {
          background-color: darken($primary, 10%); } } }

    .date,
    .top-menu-wrap {
      display: inline-block; }

    .menu-icon {
      position: absolute;
      right: 20px;
      top: 40px;
      width: 30px;
      height: 20px;
      display: none;
      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: $text;
        border-radius: 9px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: opacity 0.5s 0.5s, top 0.5s 0.4s, transform 0.5s, -webkit-transform 0.5s;
        &:nth-child(1) {
          top: 0; }
        &:nth-child(2) {
          top: 8px; }
        &:nth-child(3) {
          top: 16px; } } } }

  @include maxtablet {
    .container {
      max-width: 100%;
      flex-wrap: wrap; }
    .logo {
      width: 100%;
      text-align: center;
      a {
        display: inline-block;
        max-height: 60px;
        img {
          max-height: 60px; } } }
    .main-menu {
      width: 100%;
      text-align: center;
      .date {
        text-align: center;
        font-size: 14px;
        margin-bottom: 0;
        .separator:before {
          margin-top: 6px; } }
      .menu-icon {
        display: block; }
      .main-menu-wrap {
        width: 300px;
        position: fixed;
        top: 100%;
        top: 0;
        left: -100%;
        height: 100%;
        background: #FFF;
        padding: 30px 15px;
        transition: all .5s linear;
        &> li {
          display: block;
          text-align: left;
          margin-bottom: 15px;
          &.has-sub {
            .sub-menu {
              position: relative;
              max-height: 0;
              transition: all .3s linear; }
            &:hover {
              .sub-menu {
                max-height: 999px; } }
            .sub-menu {
              li.has-sub-2 {
                &> a:after {
                  content: "\f0d7"; }
                .sub-menu-2 {
                  position: relative;
                  list-style: none;
                  left: 0;
                  margin: 0;
                  min-width: 200px;
                  max-height: 0;
                  opacity: 0;
                  visibility: hidden;
                  transition: all .3s ease;
                  padding: 0 10px;
                  background: rgba(29, 39, 70, 0.8);
                  &> li > a {
                    display: block;
                    padding: 8px 15px;
                    background: #FFF;
                    color: $text;
                    transition: all .3s;
                    &:hover {
                      background: #f8f8f8; } } }
                &.sub-menu-open {
                  .sub-menu-2 {
                    opacity: 1;
                    max-height: 999px;
                    visibility: visible;
                    left: 0; } } } } }
          &.highlight a {
            color: #3b4f8e; }
          &.button a {
            display: inline-block;
            padding: 0 15px;
            line-height: 35px;
            background: #b82125;
            border-radius: 3px;
            color: #FFF !important; } } }


      .top-menu-wrap {
        margin: 0 -5px;
        &> li > a {
          font-size: 13px;
          line-height: 1.25em; } } } }

  @include mobile {
    .logo {
      text-align: left;
      padding-right: 45px; }
    .main-menu {
      text-align: left;
      .date {
        text-align: left; }
      .menu-icon {
        right: 15px;
        width: 24px; }
      .top-menu-wrap {
        text-align: left; }
      .main-menu-wrap {
        width: calc(100% - 70px); } } } }


body.main-menu-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  header {
    &:after {
      opacity: .7;
      visibility: visible; }
    .menu-icon {
      z-index: 3;
      span {
        background-color: #FFF;
        transition: opacity 0.5s, top 0.5s, transform 0.5s 0.4s, -webkit-transform 0.5s 0.4s;
        &:nth-child(1) {
          top: 8px;
          transform: rotate(45deg); }
        &:nth-child(2) {
          opacity: 0; }
        &:nth-child(3) {
          top: 8px;
          transform: rotate(-45deg); } } }
    .main-menu-wrap {
      left: 0;
      z-index: 3;
      overflow: auto; } } }
