/* ---------------- */
/*     CONTENT      */
/* ---------------- */

.masthead {
  .slider {
    .item {
      .img {
        width: 100%;
        padding-top: 35%;
        background-size: cover;
        background-position: center; } } }
  .owl-carousel {
    .owl-nav {
      .owl-prev,
      .owl-next {
        font-size: 3rem;
        color: #FFF;
        &:hover {
          color: $primary; }
        .fas {
          font-weight: 300; } }
      .owl-prev {
        left: 15px; }
      .owl-next {
        right: 15px; } } }
  @include mobile {
    .slider {
      .item {
        .img {
          padding-top: 50%; } }
      &.owl-carousel .owl-dots {
        bottom: 10px;
        bottom: 10px; } } } }

.slider.exhibiting {
  margin-bottom: 50px;
  .item {
    .img-wrap {
      width: 100%;
      padding-top: 40%;
      position: relative;
      img {
        width: calc(100% - 30px);
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        object-fit: contain; } } } }

.regis {
  text-align: center;
  p {
    font-size: 21px;
    line-height: 33px;
    text-align: justify; }

  .btn {
    letter-spacing: 2px;
    margin: 60px 0 0; } }
.opportunity {
  .opp-desc {
    text-align: center;
    .row {
      justify-content: center; }
    .icon {
      width: 60px;
      height: 60px;
      display: inline-block;
      // background: red
      margin-bottom: 20px;
      background-size: contain;
      background-repeat: no-repeat; }
    .col-sm-4 {
      margin-bottom: 30px; } } }

.block-testimonial {
  .blockquote {
    margin: 0 auto 30px;
    text-align: center;
    position: relative;
    padding: 0 30px;
    font-size: 1.125rem;
    &:before,
    &:after {
      font-family: 'Font Awesome\ 5 Pro';
      font-weight: bold;
      font-size: 14px;
      line-height: 14px;
      color: #6c757d;
      position: relative;
      top: -5px; }
    &:before {
      content: "\f10d";
      margin-right: 10px; }
    &:after {
      content: "\f10e";
      margin-left: 10px; } }

  .blockquote-footer {
    padding-left: 15px;
    position: relative;
    font-size: 14px;
    line-height: 21px;
    &:before {
      position: absolute;
      left: 0;
      top: 0; } }
  .img {
    text-align: center;
    img {
      display: inline-block;
      width: auto;
      height: 80px; } }
  @include mobile {
    .col-4 {
      max-width: 80%;
      flex: 0 0 80%; }
    .blockquote-footer {
      margin-bottom: 20px;
      padding-left: 0;
      text-align: center;
      &:before {
        display: none; } } } }

.historical {
  // border-top: 1px solid #DDD
  p {
    margin-bottom: 10px;
    text-align: justify;
    &:last-of-type {
      margin-bottom: 30px; } }
  .source {
    text-align: center;
    font-style: italic; } }

.supp-org {
  .items {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    .item {
      -webkit-flex: 0 0 25%;
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      width: 25%;
      position: relative;
      justify-content: center;
      display: flex;
      text-align: center;
      transition: all .6s linear;
      margin-bottom: 40px;
      .img {
        width: auto;
        max-width: 80%;
        height: 100px;
        // padding: 10px 0
        margin: 0 auto;
        object-fit: contain;
        &:hover {
          opacity: .8; } } } }
  @include mobile {
    .items {
      .item {
        // flex: 0 0 33.333%
        // width: 33.333%
        flex: 0 0 100%;
        width: 100%; } } } }


.logo-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .item {
    padding: 0;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
    img {
      display: block; } }
  @include mobile {
    margin-bottom: 40px;
    .item {
      margin-bottom: 0; } } }

.block-supports {
  .logo-wrap {
    .item {
      width: 25%;
      img {
        // width: 50%
        // height: auto
        max-height: 60px;
        max-width: 75%; }
      @include mobile {
        width: 100%; } } } }

.block-partners {
  .logo-wrap {
    .item {
      width: 25%;
      img {
        max-height: 60px;
        max-width: 75%; }
      @include mobile {
        width: 100%; } } } }

.partners {
  margin-bottom: 40px;
  &> .item {
    width: 100%;
    position: relative;
    .img {
      width: 100%;
      padding-top: 40%;
      position: relative;
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; } } } }

.events {
  .box-w-st {
    margin-bottom: 30px;
    p {
      margin-bottom: 10px;
      &:last-of-type {
        margin-bottom: 0; } }
    ul {
      padding-left: 20px;
      list-style-type: disc;
      li {} } }
  p {
    &:last-child {
      margin-bottom: 30px !important; } } }

.list-custom {
  label {
    margin-bottom: 15px; }
  &> ul {
    list-style: none;
    padding: 0 !important;
    &> li {
      background-color: #f6f6f6;
      padding: 10px 13px 10px 20px;
      margin-bottom: 20px !important;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 4px;
        height: 100%; } } }
  &.lc-primary > ul {
    &> li:before {
      background-color: $primary; } }
  &.lc-secondary > ul {
    &> li:before {
      background-color: $secondary; } } }

.list-download {
  label {
    margin-bottom: 15px; }
  &> ul {
    list-style: none;
    padding: 0 !important;
    &> li {
      margin-bottom: 25px !important;
      position: relative;
      &> a {
        background-color: #f6f6f6;
        padding: 12px 20px;
        display: inline-block;
        transition: all .3s;
        &:hover {
          color: #FFF;
          background-color: $primary; } } } } }

.block-image {
  margin-bottom: 30px;
  .box-img {
    // width: 100%
    // padding-top: 46.5%
    // position: relative
    margin-bottom: 30px;
    // .img
    //   width: 100%
    //   height: 100%
    //   position: absolute
    //   top: 0
    //   left: 0
    //   right: 0
 } }    //   bottom: 0

.map {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 40%;
  z-index: 1; }

.exhibit-wrap {
  .item {
    h4 {
      font-size: 20px;
      line-height: 1.5em; }
    img {
      margin-bottom: 30px; } } }

.list-travel {
  .item {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px solid #DDD;
    .box-img {
      position: relative;
      width: 100%;
      padding-top: 100%;
      overflow: hidden;
      border-radius: 4px;
      transition: all .5s;
      .img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: all 1s;
        object-fit: cover; }
      &:hover {
        transform: translateY(-10px);
        .img {
          transform: scale(1.1); } } } } }

.list-country {
  &> ul {
    list-style: none;
    padding: 0;
    margin: 0 0 30px;
    column-count: 4;
    column-gap: 30px;
    @include maxtablet {
      column-count: 3; }
    @include mobile {
      column-count: 2; }
    &> li {
      padding: 3px 0;
      margin-bottom: 5px;
      break-inside: avoid;
      &.alp {
        label {
          display: block;
          border-bottom: 1px solid $border;
          margin-bottom: 5px;
          padding: 5px 0;
          font-weight: 12px;
          color: $secondary;
          font-weight: 600; } } } } }

.contact {
  .lead {
    font-size: 18px;
    h4 {
      margin-bottom: 5px; }
    p {
      font-size: 18px; } }
  .contact-form {
    .required {
      position: relative;
      &:after {
        content: " *";
        color: $secondary;
        font-weight: bold; } } } }

.card {
  position: relative;
  border: 0;
  border-radius: 0;
  .card-img {
    width: 100%;
    padding-top: 60%;
    position: relative;
    display: block;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; } }
  .card-body {
    padding: 20px 0;
    border: 0; }
  p {
    margin-bottom: 30px; } }


.single-wrap {
  .block-image {
    .box-img {
      img {
        width: 100%;
        height: auto; } } }

  .date {
    span {
      font-size: 14px;
      line-height: 21px;
      display: inline-block;
      vertical-align: middle; } }
  .share {
    text-align: right;
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      line-height: 21px;
      margin-right: 15px; }
    a {
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px; }
    .fa {
      font-size: 1.25rem;
      display: inline-block;
      vertical-align: middle;
      margin: 0 0.1875rem;
      opacity: 1;
      transition: all .3s;
      &:hover {
        opacity: .5; } }
    .fa-facebook {
      color: #3F51B5; }
    .fa-twitter {
      color: #03A9F4; }
    .fa-envelope {
      color: #207EC7; } } }

.modal {
  .content-banner {
    padding: 15px;
    img {
      width: 100%;
      height: auto;
      margin-bottom: 20px; } }

  .content-banner-booth {
    img {
      width: 100%;
      height: auto; }
    textarea {
      width: 100%; } } }

.single {
  ul {
    padding-left: 25px; } }

.page-not-found {
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 190px;
    line-height: 1em;
    color: $primary;
    font-weight: 300; }
  p {
    font-size: 33px;
    line-height: 1.5em; }
  .fal {
    margin-right: 5px;
    font-size: .8em; }
  @include mobile {
    h1 {
      font-size: 72px; }
    p {
      font-size: 21px; } } }



// Steps Style
// .steps-wiz
//   .steps,
//   .actions
//     ul
//       list-style: none !important
//       padding-left: 0
//       li
//         display: inline-block
//         & + li
//           margin-left: 20px
//         .number
//           display: none !important
//         .current-info
//           display: none !important
//   .steps
//     ul
//       display: flex
//       justify-content: space-between
//       align-items: center
//       position: relative
//       margin-bottom: 30px
//       &:before
//         content: ""
//         width: 100%
//         height: 2px
//         position: absolute
//         top: calc(50% - 1px)
//         left: 0
//         background: #dbdce1
//         z-index: 0
//       li
//         position: relative
//         z-index: 2
//         background: #ffffff
//         padding: 0 20px
//         a
//           color: #dbdce1
//           font-size: 18px
//           line-height: 24px
//           font-weight: 500
//         &.current
//           a
//             color: #005A93
//         &.done
//           &:after
//             content: ""
//             width: 100%
//             height: 2px
//             position: absolute
//             top: calc(50% - 1px)
//             right: -100%
//             background: #005A93
//             z-index: 0
//           a
//             color: #005A93
//         &.last.current,
//         &.last.done
//           &:after
//             display: none
//         &.last
//           .fas
//               font-size: 16px
//               display: none
//         &.last.done,
//         &.last.current
//           a
//             .fas
//               color: #82c91e
//               display: inline-block


//   .content
//     // min-height: 100vh
//     position: relative
//     overflow: hidden
//     margin-bottom: 30px
//     h3
//       display: none
//     .form-enquiry.body
//       transition: all .8s
//       transform: translateX(-100%)
//       &.current
//         transform: translateX(0)
//     .mandatory
//       span
//         color: #b82125

//   .actions
//     ul
//       text-align: center
//       li
//         display: inline-block
//         a
//           position: relative
//           display: inline-block
//           font-size: 16px
//           line-height: 40px
//           font-weight: 400
//           padding: 0 15px
//           border: 0
//           border-radius: 3px
//           text-align: center
//           transition: all .3s ease
//           background: #b82125
//           color: #FFF
//           min-width: 150px
//         &:first-child
//           a
//             background: #003b60
//             color: #ffffff
//         &:last-child
//             display: none
//         &.disabled
//           display: none
//           a
//             background: #DDD
//             color: #444
//     &.sect2
//       ul
//         li
//           &:nth-child(2)
//             display: none
//           &:last-child
//             display: inline-block
//     &.sect3
//       display: none

.steps-wiz {
  .steps,
  .actions {
    ul {
      list-style: none !important;
      padding-left: 0;
      li {
        display: inline-block;
        & + li {
          margin-left: 20px; }
        .number {
          display: none !important; }
        .current-info {
          display: none !important; } } } }
  .steps {
    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-bottom: 30px;
      border: 0;
      &:before {
        content: "";
        width: 100%;
        height: 2px;
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
        background: #dbdce1;
        z-index: 0; }
      li {
        position: relative;
        z-index: 2;
        background: #ffffff;
        padding: 0 20px;
        outline: none !important;
        a {
          color: #dbdce1;
          font-size: 18px;
          line-height: 24px;
          font-weight: 500;
          outline: none !important;
          i {
            margin-left: 5px;
            font-size: 16px;
            line-height: 24px;
            color: #82c91e;
            display: none; }
          &.active {
            color: #005A93;
            i {
              display: inline-block; } } } } } }

  .succsess {
    padding: 30px 0;
    text-align: center;
    a {
      color: #005599;
      &:hover {
        opacity: 0.8; } } } }


// Gform Steps

.steps-wiz {
  .gf_page_steps {
    padding-left: 0;
    .gf_step {
      display: inline-block;
      vertical-align: middle;
      & + .gf_step {
        margin-left: 20px; }
      .gf_step_number {
          display: none !important; } } }

  .gf_page_steps {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 30px !important;
    border: 0;
    border-bottom: 0 !important;
    &:before {
      content: "";
      width: 100%;
      height: 2px;
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      background: #dbdce1;
      z-index: 0; }
    .gf_step {
      position: relative;
      z-index: 2;
      background: #ffffff;
      padding: 0 20px;
      outline: none !important;
      margin: 0;
      opacity: 1 !important;
      height: auto !important;
      span {
        background: #ffffff;
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        outline: none !important; }
      &.gf_step_last {
        order: 1; }
      &.gf_step_active {
        span {
          color: #005A93; } }
      &.gf_step_completed {
        &:after {
          content: "";
          width: 242%;
          height: 2px;
          position: absolute;
          top: calc(50% - -1px);
          right: -242%;
          background: #005A93;
          z-index: 0; } } } }

  .gform_page_footer {
    border-top: 0 !important;
    text-align: center;
    .button {
      position: relative;
      font-size: 16px;
      line-height: 40px;
      font-weight: 400;
      border: 0;
      border-radius: 3px;
      text-align: center;
      transition: all .3s ease;
      padding: 0 30px;
      min-width: 120px;
      &.gform_next_button,
      &.gform_button {
        background: #b82125;
        color: #FFF; }
      &.gform_previous_button {
        background: #003b60;
        color: #FFF; }
      &:hover {
        cursor: pointer; } } }

  .gform_wrapper {
    ul.gfield_radio {
      li {
        input + input {
          margin-left: 0 !important; } } }
    .gfield_required {
      display: inline-block !important;
      color: #b82125 !important; } } }

.milestone {
  ul {
    list-style: none;
    padding: 20px 0 !important;
    margin: 0;
    width: 100%;
    text-align: center;
    position: relative;
    li {
      display: inline-block;
      margin: 0 10px 15px !important;
      position: relative;
      a {
        background-color: #FFF;
        border: 2px solid $text;
        border-radius: 5px;
        display: inline-block;
        padding: 5px 15px;
        font-size: 20px;
        line-height: 30px;
        font-weight: 600;
        transition: all .3s linear;
        position: relative;
        z-index: 2;
        &:focus,
        &:active {
          color: $text; }
        &:hover {
          color: #FFF;
          background-color: $primary;
          border-color: $primary;
          &:before {
            background-color: $primary; } } }
      &:before {
        content: "";
        display: block;
        width: 30px;
        height: 2px;
        background-color: $text;
        position: absolute;
        top: calc(50% - 1px);
        right: 100%;
        z-index: 0; }
      &:first-child:before {
        display: none; } } } }

.participation-scheme {
  margin-bottom: 50px;
  .item {
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0; }
    .col + .col {
      min-width: calc(100% - 300px); }
    h3 {
      font-size: 20px;
      line-height: 30px;
      a {
        color: $primary; } }
    .box-img {
      max-width: 270px; }
    .text-content {} }
  @include mobile {
    .item {
      .col + .col {
        min-width: 100%; }
      .box-img {
        max-width: 100%;
        margin-bottom: 20px;
        img {
          width: 100%; } } } } }
